(function ( $ ) {

    let $mobile_trigger;
    let $menu_wrapper;
    let $parent_menu_links;

    function is_mobile () {
        return $mobile_trigger.is(':visible');
    }

    $(function () {
        $mobile_trigger = $('.mobile-menu-trigger');
        $menu_wrapper = $('.menu-header-menu-container').parent();
        $parent_menu_links = $( 'li.menu-item-has-children > a', $menu_wrapper );

        $mobile_trigger.click( function(){
            $menu_wrapper.fadeToggle();
        });
        
        $parent_menu_links.click( function ( e ) {
            if ( !is_mobile() ) { return; }
            let targ = e.pageX;
            let off  = $( window ).innerWidth();
            if( off - targ <= 55 ){
                e.preventDefault();
                $(this).parent().find('.sub-menu').slideToggle();
            }
        });
    })

})( jQuery );