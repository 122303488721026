( function ( $ ) {

    let $button = $( '<button>' ).attr( 'id', 'scroll-to-top' ).addClass( 'btn-primary' ).html( $( '<span>' ).addClass( 'sr-only' ).html( 'Scroll to top' ) ).hide();
    let from_top_cutoff = 100;

    $button.on( 'click', function () {
        $('html, body').animate(
            { scrollTop: 0 },
            1000
        );
    });

    $( window ).on( 'scroll resize', function () {
        let from_top = $( window ).scrollTop();
        let visible = $button.is(':visible');
        if ( from_top < from_top_cutoff && visible ) {
            $button.fadeOut();
        } else if ( from_top >= from_top_cutoff && !visible ) {
            $button.fadeIn();
        }
        let from_bottom = $( document).height() - ( $(window).height() + from_top );
        $( 'body' ).toggleClass( 'near-scroll-bottom', ( from_bottom < 50 ) );
    });

    $( function () {
        $( 'body' ).append( $button ).addClass( 'scroll-button-added' );
    });

})( jQuery );