ThemeJS.Image_Gallery = ( function ( $, root ) {

    function init_gallery ( $gallery ) {
        let $bg_images = $( 'a.bg-img', $gallery );
        if ( $bg_images.length < 2 ) {
            // bail if not displaying as gallery
            return;
        }
        let $modal = $( 'div.modal', $gallery );
        let $slider = $( '.slick-slider', $modal );
        let clicked_index;

        $slider.on( 'init_slick', function ( e, options ) {
            options.slidesToShow = 1;
            options.slidesToScroll = 1;
        });

        $bg_images.click( function () {
            if ( $( 'img', this ).is(':visible') ) {
                // break if already displaying full img tag
                return;
            }
            clicked_index = $(this).parent().index();
            $modal.modal('show');
        });

        $modal.on( 'show.bs.modal', function () {
            setTimeout( function () {
                $slider.slick( 'slickGoTo', clicked_index, true );
                $(window).trigger('resize');
            }, 200 );
        });
    }

    $(function () {
        $('.image-gallery').each( function () {
            init_gallery( $(this) );
        });
    });

})( jQuery, this );