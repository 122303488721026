ThemeJS.Accordions = ( function ($) {

    let slide_duration = 300;

    function init_list ( $list ) {
        let $items = $( '> .accordion-item', $list );
        let $headings = $( '> .link-wrapper .heading', $items );
        let $contents = $( '> .content-wrapper', $items );

        $items.filter('.force-open').each( function () {
            $( '> .content-wrapper', this ).css('display', 'block');
            $(this).removeClass('force-open');
        });

        $headings.off( 'click', toggle_item );
        $headings.click( { $items, $headings, $contents }, toggle_item );
    }

    function toggle_item ( e ) {
        // scoped to .heading
        let $this_item = $(this).closest( '.accordion-item' );
        let closing = $this_item.hasClass('open');
        $this_item.trigger( 'accordion_item_toggled', !closing );
        if ( closing ) {
            $this_item.removeClass('open');
            $( '> .content-wrapper', $this_item ).slideUp( slide_duration );
        } else {
            // e.data.$items.removeClass('open');
            // e.data.$contents.slideUp( slide_duration );
            $this_item.addClass('open');
            $( '> .content-wrapper', $this_item ).slideDown( slide_duration );
        }
    }

    function init () {
        $('.accordion-list').each(function () {
            init_list( $(this) );
        });
    }

    $( init );

    return {
        init,
        init_list
    }

})( jQuery );