( function ( $ ) {

    /*****************************\
     * JS Fixes for GForms Issues
    \*****************************/

    $( 'input.datepicker' ).each(function () {
        // disable browser autocomplete by generating random autocomplete attribute
        $(this).attr( 'autocomplete', Math.random().toString(36).substring(7) );
    });

    $( '.gfield_radio input[type=radio][value="gf_other_choice"]' ).each( function () {
        let $radio = $( this );
        let $text = $( this ).siblings( 'input[type=text]' );
        $( `<label for="${ this.id }"><span class="sr-only">Other</span></label>` ).insertAfter( this );
        $radio.change( function () {
            if ( $(this).val() ) {
                $text.focus();
            }
        });
        $text.removeAttr( 'onfocus' ).focus( function () {
            $radio.click();
            if( $( this ).val() == "Other" ) {
                $(this).val( "" );
            }
        });
    })

})( jQuery );