/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/


// 1-line jQuery plugin!
jQuery.fn.reverse = [].reverse;


ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
        });
    }

    function array_unique ( array ) {
        return array.filter( ( elem, pos, arr ) => {
            return arr.indexOf( elem ) == pos;
        });
    }

    function is_IE () {
        // modified from https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
        // returns bool : is IE 11
        return window.navigator.userAgent.indexOf('Trident/') > 0;
    }

    function get_query_parameter ( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function set_query_parameter ( key, value, url ) {
        if (!url) url = window.location.href;
        let baseUrl = url.split('?')[0],
            urlQueryString = '?' + url.split('?')[1],
            newParam = key + '=' + value,
            params = '?' + newParam;
    
        if ( urlQueryString ) {
            let updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            let removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');
            if (typeof value === 'undefined' || value === null || value === '') { // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");
            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);
            } else {
                params = urlQueryString + '&' + newParam;
            }
        }
        params = params === '?' ? '' : params;
        return baseUrl + params;
    }

    function untrailing_slash_it ( str ) {
        return str.replace( /\/$/, '' );
    }
      
    function trailing_slash_it ( str ) {
        return untrailing_slash_it( str ) + '/';
    }

    $(function () {

        // smooth-scroll / toggle modal on click of id href
        $( document ).on( 'click', 'a[href^="#"]', function ( e ) {
            let $target = $( $(this).attr('href') );
            if ( $target.length ) {
                e.preventDefault();
                if ( $target.hasClass('modal') ) {
                    $target.modal('toggle');
                    return;
                }
                $('html, body').animate(
                    { scrollTop: $target.offset().top },
                    1000,
                    function() {
                        $target.focus();
                        if ( $target.is(":focus") ) {
                            return false;
                        } else {
                            $target.attr('tabindex','-1');
                            $target.focus();
                        }
                    }
                );
            }
        });

    });
    
    return {
        send_ajax_request,
        array_unique,
        is_IE,
        QueryParams: {
            get: get_query_parameter,
            set: set_query_parameter
        },
        TrailingSlashes: {
            add: trailing_slash_it,
            remove: untrailing_slash_it
        }
    }

})( jQuery, this );