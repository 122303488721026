( function ( $ ) {

    $(document).on( 'init_slick', '.slider-with-nav .slider-nav .slick-slider', function ( e, options ) {
        options.slidesToShow = 4;
        options.slidesToScroll = 1;
        options.nextArrow = '<button class="slick-next slick-arrow btn btn-gray-900" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>';
        options.asNavFor = `#${ $(this).data().main }`;
        options.focusOnSelect = true;
    });

    $(document).on( 'init_slick', '.slider-with-nav .slider-main .slick-slider', function ( e, options ) {
        options.arrows = false;
        options.slidesToShow = 1;
        options.slidesToScroll = 1;
        options.fade = true;
        options.asNavFor = `#${ $(this).data().nav }`;
        options.responsive = [
            {
                breakpoint: 991,
                settings: {
                    arrows: true,
                    nextArrow: '<button class="slick-next slick-arrow btn btn-gray-900" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>',
                }
            },
        ];
    });

    $(document).on( 'beforeChange', '.slider-with-nav .slider-main .slick-slider', function () {
        let $video = $(this).parent().find('video');
        if ( $video.length ) {
            $video[0].pause();
        }
    });

})( jQuery );