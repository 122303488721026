( function ( $ ) {

    $(document).on( 'init_slick', '.hero-banner.slick-slider', function ( e, options ) {
        let $slider = $(this);

        options.slidesToShow = 1;
        options.slidesToScroll = 1;
        options.focusOnSelect = true;
        options.dots = true;
        options.prevArrow = '<button class="slick-prev slick-arrow btn btn-no-bg" aria-label="Previous" type="button" style="display: block;"><i class="far fa-chevron-left"></i></button>';
        options.nextArrow = '<button class="slick-next slick-arrow btn btn-no-bg" aria-label="Next" type="button" style="display: block;"><i class="far fa-chevron-right"></i></button>';

        let slide_speed = parseInt( $slider.data().autoplay );
        if ( slide_speed ) {
            options.autoplay = true;
            options.autoplaySpeed = slide_speed * 1000;

            // autoplay only one round
            $slider.on( 'afterChange', function( event, slick, currentSlide ) {
                if ( 0 === currentSlide ) {
                    $slider.slick( 'slickPause' );
                };
            });
        }

        delete options.responsive;

    });

})( jQuery );