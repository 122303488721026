( function ( $ ) {

    $(document).on( 'init_slick', '.testimonials-slider .slick-slider', function ( e, options ) {
        options.slidesToShow = 1;
        options.slidesToScroll = 1;
        options.prevArrow = '<button class="slick-prev slick-arrow btn btn-no-bg" aria-label="Previous" type="button" style="display: block;"><i class="fas fa-chevron-left"></i></button>';
        options.nextArrow = '<button class="slick-next slick-arrow btn btn-no-bg" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>';
        options.focusOnSelect = true;
        delete options.responsive;
    });

})( jQuery );