ThemeJS.Accordion_Search = ( function ($) {

    let slide_speed = 250;

    function init_form ( $form ) {
        let search_key = $( '.search-key', $form ).val();
        let $searchable_sections = $(`.accordion-list[data-search_key="${search_key}"]`);
        if ( !$searchable_sections.length ) { return; }
        let $search_field = $( '.search-term', $form );
        let $filter_wrap = $( '.filter-wrap', $form );
        let $active_filter = $( '.active-filter', $filter_wrap );
        let $clear_filter = $( '.clear-filter', $filter_wrap );

        $form.submit( function ( e ) {
            e.preventDefault();
            let search_text = $search_field.val().trim();

            if ( search_text ) {
                $active_filter.html( search_text );
                $filter_wrap.slideDown( slide_speed );
            } else {
                $filter_wrap.slideUp( slide_speed );
            }
            
            let search_terms = ThemeJS.Functions.array_unique( search_text.toLowerCase().split(' ') ).filter( x => x );
            $searchable_sections.each( function () {
                filter_section( $(this), search_terms );
                document.activeElement.blur();
            });
        });

        $clear_filter.click( function ( e ) {
            e.preventDefault();
            $search_field.val( '' );
            $form.submit();
        });

    }

    function filter_section ( $section, terms ) {
        let $accordion_items = $( '.accordion-item', $section );
        $accordion_items.each( function () {
            let $links = $( '.content-wrapper a', this );
            $links.each( function () {
                if ( !terms.length ) {
                    $(this).removeClass('d-none');
                    return;
                }
                let hide = true;
                let html = $(this).html().toLowerCase();
                terms.forEach( function ( term ) {
                    if ( html.includes( term ) ) {
                        hide = false;
                        return false;
                    }
                });
                $(this).toggleClass( 'd-none', hide );
            });
            $(this).toggleClass( 'd-none', $links.not('.d-none').length == 0 );
        });
        $section.toggleClass( 'filtered-empty', $accordion_items.not('.d-none').length == 0 );
    }

    function init () {
        $( 'form.accordion-search-form' ).each( function () {
            init_form( $(this) );
        });
    }

    $( init );
    return {
        init
    }

})( jQuery );