ThemeJS.Sliders = (function ( $ ) {

    let default_options = {
        infinite:true,
        dots:false,
        arrows:true,
        autoplay:false,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: '<button class="slick-prev slick-arrow btn btn-secondary" aria-label="Previous" type="button" style="display: block;"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button class="slick-next slick-arrow btn btn-secondary" aria-label="Next" type="button" style="display: block;"><i class="fas fa-chevron-right"></i></button>',
        lazy: 'ondemand',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    function init_slider ( $slider ) {
        // clone into local options to pass through event as filter
        let options = $.extend( true, {}, default_options );
        $slider.trigger( 'init_slick', options );
        if ( $( '.slick-slide', $slider ).length < 2 ) {
            // bail from initing slick if not needed
            return;
        }
        $slider.slick( options );

        if ( ThemeJS.Functions.is_IE() ) {
            // force animationless slide away from and back to initial slide in IE to fix flex height issue
            $slider.slick( 'slickGoTo', -1, true );
            $slider.slick( 'slickGoTo', 0, true );
        }

        $slider.on('beforeChange', function( event, slick, currentSlide, nextSlide ) {
            $(this).addClass('sliding');
        });
        $slider.on('afterChange', function( event, slick, currentSlide, nextSlide ) {
            $(this).removeClass('sliding');
        });
    }

    function init () {
        $( '.slick-slider' ).each( function () {
            init_slider( $(this) );
        });
    }

    $( function () {
        init();
    });

    return {
        init
    };

})( jQuery );